import { Link } from "react-router-dom";
import SmallLogo from "../../assets/healthlink360-mini-logo.jpeg";
import styles from "./WorkInProgress.module.css";

export const WorkInProgress = () => {
  return (
    <section className={styles.workInProgressContainer}>
      <div className={styles.workInProgressBackToHome}>
        <Link to="/home" className={styles.backLink}>
          <span>
            <i className="fa-duotone fa-solid fa-chevron-left"></i>
          </span>
        </Link>
      </div>
      <div className={styles.descriptionContainer}>
        <figure>
          <img src={SmallLogo} alt="HealthLink360 small logo without letters" />
        </figure>
        <p>Think of this like a wellness check-up it's in progress, and we're making sure it's in peak condition before it goes live!</p>
        <p>Stay tuned!</p>
      </div>
    </section>
  )
}
