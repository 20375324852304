import { useEffect } from "react";
import { useBasicInformation } from "./useBasicInformation";
import { StoredState } from "../lib/storedState";

export const useOnboardingData = (): void => {
  const completeBasicInformation = useBasicInformation();
  const parsedState: StoredState | null = JSON.parse(
    localStorage.getItem("storedState") ?? "null",
  );

  useEffect(() => {
    if (!completeBasicInformation) return;

    const [_, basicInformation] = completeBasicInformation;

    const saveOnboardingQuestions = async () => {
      const headers = new Headers();
      headers.append("Authorization", `Bearer ${parsedState?.jwt}`);
      const apiEndpoint = new URL("/users/onboarding", process.env.REACT_APP_API_BASE_URL);
      const response = await fetch(apiEndpoint, {
        method: "POST",
        headers,
        body: JSON.stringify(basicInformation)
      });
      await response.json();
    }

    saveOnboardingQuestions();
  }, []);
};
